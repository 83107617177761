import { createInstance } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next/initReactI18next'
import { getOptions } from './settings'

const initI18next = async (locale: string, ns: string) => {
  const i18nInstance = createInstance()
  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`./locales/${language}/${namespace}.json`),
      ),
    )
    .init(getOptions(locale, ns))
  return i18nInstance
}

export const getTranslation = async (
  locale: string,
  options?: {
    ns?: string
    keyPrefix?: string
  },
) => {
  const { ns = 'common', keyPrefix } = options || {}
  const i18nextInstance = await initI18next(locale, ns)
  return {
    t: i18nextInstance.getFixedT(
      locale,
      Array.isArray(ns) ? ns[0] : ns,
      keyPrefix,
    ),
    i18n: i18nextInstance,
  }
}

export const useTranslation = getTranslation
