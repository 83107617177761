'use client'
import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'app/i18n/client'

import Button from 'app/components/atoms/Button'

import useGetMobileDevice from 'app/hooks/useGetMobileDevice'

import {
  PLAY_STORE_LINK,
  APP_STORE_LINK,
  DEVICE_NAMES,
  STAGING_FIREBASE_DYNAMIC_LINK,
} from 'utils/constants'
import { usePathname } from 'next/navigation'
import FaviIcon from 'public/favicon.ico'

const MobileBanner: React.FunctionComponent = () => {
  const dynamicLink = process.env.NEXT_PUBLIC_FIREBASE_DYNAMIC_APP_LINK
    ? process.env.NEXT_PUBLIC_FIREBASE_DYNAMIC_APP_LINK
    : STAGING_FIREBASE_DYNAMIC_LINK

  const deviceName = useGetMobileDevice()

  const { t } = useTranslation()

  const pathname = usePathname()

  if (pathname?.split('/')?.length && pathname?.split('/')?.length > 2)
    return null

  return (
    <div className="bg-primary-light flex justify-between py-3 px-2 items-center lg:hidden">
      <div>
        <Link href="/" passHref legacyBehavior>
          <div className="flex gap-1 flex-row items-center rounded-lg">
            <Image
              width={42}
              height={42}
              src={FaviIcon}
              alt="Tokyu Stay App"
              className="cursor-pointer"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <p className="text-xs pt-2 font-semibold">
              {t('Tokyu-Stay official app')}
            </p>
          </div>
        </Link>
      </div>
      <div className="flex gap-2">
        <Button
          className="text-white rounded-sm font-semibold"
          size="sm"
          variant="primary"
        >
          <Link
            href={
              deviceName === DEVICE_NAMES.IOS
                ? APP_STORE_LINK
                : deviceName === DEVICE_NAMES.ANDROID
                  ? PLAY_STORE_LINK
                  : '#'
            }
            legacyBehavior
          >
            {t('Install')}
          </Link>
        </Button>
        <Button
          className="bg-white font-semibold rounded-sm !text-gray-dark"
          size="sm"
        >
          <Link href={dynamicLink} legacyBehavior>
            {t('Open in App')}
          </Link>
        </Button>
      </div>
    </div>
  )
}

export default MobileBanner
