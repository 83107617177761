import { useEffect, useState } from 'react'
import { DEVICE_NAMES } from 'utils/constants'

const useGetMobileDevice = () => {
  const [deviceName, setDeviceName] = useState<string>('')

  useEffect(() => {
    const getMobileOS = () => {
      const ua = window !== undefined ? navigator.userAgent : ''
      if (/android/i.test(ua)) {
        return DEVICE_NAMES.ANDROID
      } else if (
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) {
        return DEVICE_NAMES.IOS
      }
      return DEVICE_NAMES.OTHERS
    }

    setDeviceName(getMobileOS())
  }, [])

  return deviceName
}

export default useGetMobileDevice
