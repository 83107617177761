'use client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import { SessionProvider } from 'next-auth/react'
import BaseUrlContextProvider from '../BaseUrlContext'

import { deleteUser } from 'lib/client/user'
import { getTranslation } from 'app/i18n'
import { toast } from 'react-toastify'

import PersonalInfoContextProvider from '../PersonalInfoContext'

const onError = async (error: any) => {
  const rollbar = (window as any)?.Rollbar
  if ((error as any)?.response?.status === 503) {
    window.location.href = '/maintenance'
  }

  if (error?.response?.status === 401) {
    deleteUser()

    window.location.href = `/authentication/sign-in?redirectUrl=${encodeURIComponent(
      `${window?.location?.pathname}${window?.location?.search}`,
    )}`
  }

  if (error?.response?.status === 502) {
    const language = window?.location?.pathname
      ?.match(/\/([a-zA-Z-_]*)/g)?.[0]
      ?.replace('/', '')

    const { t } = await getTranslation(language || 'ja')

    toast.error(`502: ${t('There are something wrong')}`)
  }

  if (rollbar) rollbar?.error(error)
}

const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        onError,
      },
      mutations: {
        onError,
      },
    },
  })

export const AppContext: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [queryClient] = useState(() => getQueryClient())

  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <PersonalInfoContextProvider>
          <BaseUrlContextProvider>{children}</BaseUrlContextProvider>
        </PersonalInfoContextProvider>
      </QueryClientProvider>
    </SessionProvider>
  )
}
