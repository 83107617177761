'use client'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const GoogleAnalytic = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_CLIENT_ID
        ? process.env.NEXT_PUBLIC_GTM_CLIENT_ID
        : '',
    })
  }, [])

  return null
}

export default GoogleAnalytic
