'use client'

import { createContext, useState } from 'react'

interface PersonalInfoContextContextValue {
  data: {
    email?: string
    firstName?: string
    lastName?: string
    firstNameKana?: string
    lastNameKana?: string
    reservationNo?: string
  }
  setEmail?: (email?: string) => void
  setFirstName?: (firstName?: string) => void
  setLastName?: (lastName?: string) => void
  setFirstNameKana?: (firstNameKana?: string) => void
  setLastNameKana?: (lastNameKana?: string) => void
  setReservationNo?: (reservationNo?: string) => void
}

export const PersonalInfoContext =
  createContext<PersonalInfoContextContextValue>({
    data: {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      firstNameKana: undefined,
      lastNameKana: undefined,
      reservationNo: undefined,
    },
    setEmail: undefined,
    setFirstName: undefined,
    setLastName: undefined,
    setFirstNameKana: undefined,
    setLastNameKana: undefined,
    setReservationNo: undefined,
  })

const PersonalInfoContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [firstName, setFirstName] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string | undefined>(undefined)
  const [firstNameKana, setFirstNameKana] = useState<string | undefined>(
    undefined,
  )
  const [lastNameKana, setLastNameKana] = useState<string | undefined>(
    undefined,
  )
  const [reservationNo, setReservationNo] = useState<string | undefined>(
    undefined,
  )

  return (
    <PersonalInfoContext.Provider
      value={{
        data: {
          email,
          firstName,
          lastName,
          firstNameKana,
          lastNameKana,
          reservationNo,
        },
        setEmail,
        setFirstName,
        setLastName,
        setFirstNameKana,
        setLastNameKana,
        setReservationNo,
      }}
    >
      {children}
    </PersonalInfoContext.Provider>
  )
}

export default PersonalInfoContextProvider
